import React from 'react';
import {
  Map,
  Wind,
  Accountinfo,
  Accountsettings,
  Addwidget,
  Arrowdown,
  Arrowleft,
  Arrowright,
  Arrowtop,
  Cartbuy,
  Cartsell,
  Catalog,
  Close,
  Comments,
  Dew,
  Email,
  Eye,
  Follow,
  Heart,
  Home,
  Humidity,
  Menu,
  Minus,
  Name,
  Notifications,
  Plus,
  Rain,
  Search,
  Seed,
  Send,
  Settings,
  Share,
  Smartphone,
  Suggestion,
  Sun,
  Sunrise,
  Sunset,
  User,
  Veterinary,
  Question,
  Calendar,
  Size,
  Time,
  Price,
  Check,
  WarningBlob
} from './icons/components';


export const IconType: Record<IconNames, any> = {
  arrow_down: <Arrowdown/>,
  add_widget: <Addwidget/>,
  account_settings: <Accountsettings/>,
  name: <Name/>,
  account_info: <Accountinfo/>,
  arrow_left: <Arrowleft/>,
  arrow_right: <Arrowright/>,
  arrow_top: <Arrowtop/>,
  cart_buy: <Cartbuy/>,
  cart_sell: <Cartsell/>,
  catalog: <Catalog/>,
  close: <Close/>,
  comments: <Comments/>,
  dew: <Dew/>,
  email: <Email/>,
  eye: <Eye/>,
  follow: <Follow/>,
  heart: <Heart/>,
  home: <Home/>,
  humidity: <Humidity/>,
  map: <Map/>,
  menu: <Menu/>,
  minus: <Minus/>,
  notifications: <Notifications/>,
  plus: <Plus/>,
  rain: <Rain/>,
  search: <Search/>,
  seed: <Seed/>,
  send: <Send/>,
  settings: <Settings/>,
  share: <Share/>,
  smartphone: <Smartphone/>,
  suggestion: <Suggestion/>,
  sun: <Sun/>,
  sunrise: <Sunrise/>,
  sunset: <Sunset/>,
  user: <User/>,
  veterinary: <Veterinary/>,
  wind: <Wind/>,
  calendar: <Calendar/>,
  question: <Question/>,
  size: <Size/>,
  time: <Time/>,
  price: <Price/>,
  check: <Check/>,
  warning_blob: <WarningBlob/>
}

export type IconNames =
  'map'|
  'wind' |
  'veterinary' |
  'user' |
  'sunset' |
  'sunrise' |
  'sun' |
  'suggestion' |
  'smartphone' |
  'share' |
  'settings'|
  'send' |
  'seed' |
  'search' |
  'rain' |
  'plus' |
  'notifications' |
  'name' |
  'menu' |
  'minus' |
  'humidity' |
  'home' |
  'heart' |
  'follow' |
  'eye' |
  'email' |
  'dew' |
  'comments' |
  'close' |
  'catalog' |
  'cart_sell' |
  'cart_buy' |
  'arrow_top' |
  'arrow_right' |
  'arrow_left' |
  'arrow_down' |
  'add_widget' |
  'account_settings' |
  'account_info'|
  'question' |
  'calendar' |
  'size' |
  'time' |
  'price' |
  'check' |
  'warning_blob'


import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: 'Proxima Nova';
    src: url('/assets/ProximaNova-Regular.eot');
    src: url('/assets/ProximaNova-Regular.eot') format('embedded-opentype'),
    url('./assets/ProximaNova-Regular.woff') format('woff'),
    url('./assets/ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('./assets/ProximaNova-Semibold.eot');
    src: url('./assets/ProximaNova-Semibold.eot') format('embedded-opentype'),
    url('./assets/ProximaNova-Semibold.woff') format('woff'),
    url('./assets/ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Proxima Nova';
    src: url('./assets/ProximaNova-Bold.eot');
    src: url('./assets/ProximaNova-Bold.eot') format('embedded-opentype'),
    url('./assets/ProximaNova-Semibold.woff') format('woff'),
    url('./assets/ProximaNova-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
`;
import styled from 'styled-components';
import {Colors} from '../Colors';

export const Wrapper = styled.div`
  background: ${Colors.secondaryGray};
  border-radius: 16px;
  width: 48px;
  height: 48px;
  
  & img {
    width: 48px;
    height: 48px;
    border-radius: 16px;
  }
`;

import styled from 'styled-components';
import {Colors} from '../Colors';

export type LinkStyledProps = {
  color: 'default' | 'primary'
  underlined?: boolean
}

const defaultColor = `
  * {
    color: ${Colors.secondaryDarkGray};
    fill: ${Colors.secondaryDarkGray}
  }
`;

const primaryColor = `
  * {
    color: ${Colors.green};
    fill: ${Colors.green}
  }
`;

const renderColor = (color: LinkStyledProps['color']) => {
  const variants: Record<LinkStyledProps['color'], any> = {
    default: defaultColor,
    primary: primaryColor
  }

  return variants[color];
}

export const Label = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-right: 4px;
  margin-left: 4px;
`;


export const Wrapper = styled.div<LinkStyledProps>`
  display: flex;
  height: 16px;
  align-items: center;
  cursor: pointer;
  ${({color}) => renderColor(color)};
  ${({underlined}) => underlined && `
    & ${Label} {
      text-decoration: underline;
    }
  `}
  
`;


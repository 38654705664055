import styled from 'styled-components';
import { Colors } from '../Colors';

type PhoneInputStyledProps = {
  disabled: boolean;
}

export const Wrapper = styled.div<PhoneInputStyledProps>`
  position: relative;
 
  & input {
    width: 100%;
    height: 40px;
    padding: 12px 15px 12px 60px;

    background: ${({disabled}) => disabled ? Colors.generalWhite : Colors.secondaryGray} ;
    border: 0.5px solid ${Colors.borderGray};
    box-sizing: border-box;
    border-radius: 12px;
    font-style: normal;
    font-weight: normal;
    outline: none;
    font-size: 12px;
    line-height: 16px;
    color: ${({disabled}) => disabled ? Colors.secondaryDarkGray : Colors.generalDark};
  }
  
  & input::placeholder {
    color: ${Colors.secondaryDarkGray};
  }
`;

export const CountrySelectWrapper = styled.div`
  position: absolute;
  left: 17px;
  top: 20px;
`;


export const CountrySelectHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 32px;
  
  & svg {
    width: 8px;
    margin-top: 2px;
  }
`;

export const CountrySelectBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 26px;
  background: ${Colors.secondaryGray};
  border: 0.5px solid ${Colors.borderGray};
  border-top: none;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 5px;
  margin-top: -1px;
  margin-left: -5px;
`;

export const CountryIcon = styled.img`
  width: 14px;
  height: 9px;
  border-radius: 2px;
  filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.23));
`;

export const Error = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: #F29993;
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.secondaryDarkGray};
  margin: 0 0 4px;
`

import styled from 'styled-components';
import {Colors} from '../Colors';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  * {
    box-sizing: border-box;
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const Label = styled.p`
  margin: 0 0 0 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.secondaryDarkGray};
`;

export type RadioButtonStyledProps = {
  isActive: boolean;
}

export const CheckMark = styled.div<RadioButtonStyledProps>`
  background: ${Colors.secondaryGray};
  border: 1px solid ${Colors.borderGray};
  width: 16px;
  height: 16px;
  border-radius: 12px;
  
  
  & > div {
    ${({isActive}) => !isActive && 'display: none;'};
    width: 14px;
    height: 14px;
    border-radius: 12px;
    background: ${Colors.generalWhite};
    border: 2px solid ${Colors.green};
  }
`;

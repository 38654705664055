import React, {FC, useEffect, useState} from 'react';
import {
    CountryIcon,
    CountrySelectBody,
    CountrySelectHeader,
    CountrySelectWrapper,
    Wrapper,
    Error,
    Label
} from './style';
import InputMask from 'react-input-mask';
import { Icon } from '../Icon';
import { Colors } from '../Colors';
import RussiaIcon from './assets/russia.svg';
import {ActionLabel, ActionLabelImg, TopBlock} from "../Input/style";
import editIcon from "../Input/edit.svg";

type CountryCode = '+7' | '+380';

type CountryOption = {
    icon: string;
    code: CountryCode
}

export type PhoneInputProps = {
    error?: string;
    onChange: (v: string, code: CountryCode) => void;
    label?: string;
    mask?: string;
    disabled?: boolean;
    defaultValue?: string;
    defaultCountryCode?: CountryCode;
    dataTestId?: string;
    placeholder?: string;
    resetRnd?: number;
    actionButton?: {
        label: string;
        onClick: () => void;
    }
};

const PhoneInput: FC<PhoneInputProps> = (
    {
        error = '',
        onChange,
        label,
        mask='(999) 999 99 99',
        disabled,
        defaultValue,
        defaultCountryCode,
        dataTestId,
        placeholder,
        actionButton,
        resetRnd,
    }) => {
    const phoneFormat = (input: string) => {
        const a =
         input.replace(/\D/g, '')
            .replace(/(...)(...)(....)/, '($1) $2-$3');
        return `${countryCode} ${a}`;
    }

    const [value, setValue] = useState(defaultValue ? phoneFormat(defaultValue) : "");
    const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountryCode || "+7");
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

    const countries: Array<CountryOption> = [
        { icon: RussiaIcon, code: '+7' },
    ];

    const options = countries.filter(item => item.code !== countryCode);

    const renderItem = (item: CountryOption) => (
        <CountryIcon src={ item.icon } onClick={ () => handleSelectCountry(item.code) } key={ item.code }/>
    );


    const handleSelectCountry = (code: CountryCode) => {
        setCountryCode(code);
        setIsSelectOpen(false)
    };

    const handleChange = (v: string) => {
        setValue(v);
        onChange(v, countryCode);
    }


    useEffect(() => {
        // const preparedSting = `${countryCode}`

        setValue(defaultValue ? phoneFormat(defaultValue) : '');

    }, [defaultValue])

    useEffect(() => {
        if (Boolean(resetRnd)) {
            setValue('');
        }
    }, 
    [resetRnd]);

    return (
        <Wrapper
            // @ts-ignore
            disabled={ disabled }
        >
            <TopBlock>
                { label && <Label>{ label }</Label> }
                {actionButton ? (<ActionLabel onClick={actionButton?.onClick}>
                    {actionButton?.label}
                    <ActionLabelImg src={editIcon}/>
                </ActionLabel>) : null}
            </TopBlock>
            <InputMask
                disabled={ disabled }
                mask={ `${ countryCode } ${mask}` }
                value={ value }
                onChange={ (event) => handleChange(event.target.value) }
                data-test-id={dataTestId}
                className="phoneInput"
                placeholder={placeholder}
            />
            <CountrySelectWrapper>
                <CountrySelectHeader
                    onClick={ () => {
                        if (!disabled) {
                            setIsSelectOpen(!isSelectOpen)
                        }
                    } }>
                    <CountryIcon src={ countries.filter(item => item.code === countryCode)[0].icon }/>
                    <Icon size={ 'small' } fill={ Colors.secondaryDarkGray } icon={ 'arrow_down' }/>
                </CountrySelectHeader>
                { isSelectOpen && (
                    <CountrySelectBody>
                        { options.map(item => renderItem(item)) }
                    </CountrySelectBody>
                ) }
            </CountrySelectWrapper>
            { error && <Error data-test-id={dataTestId+'error'}>{ error }</Error> }
        </Wrapper>
    )
}

export default PhoneInput;

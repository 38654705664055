import styled from "styled-components";
import {Colors} from "../Colors";



export type SelectHeaderStyledProps = {
    isOpen: boolean
}

const renderIsOpenState = (isOpen: boolean) => isOpen
    ? `
        border: 0.5px solid ${Colors.borderGray};
        border-bottom: none;
        
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        
    ` :
    `
        border: 0.5px solid ${Colors.borderGray};
    `

export const SelectHeader = styled.div`
    width: 100%;
    height: 40px;
    background: ${Colors.secondaryGray};
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
`



export const SelectIcon = styled.div`
    width: 12px;
    height: 12px;
`

export const SelectInput = styled.input`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.generalDark};
    background: none;
    border: none;
    outline: none;
  
    &::placeholder {
      color: ${Colors.secondaryDarkGray};
    }
`

export const SelectBody = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;
  border: 0.5px solid ${Colors.borderGray};
  border-top: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const SelectList = styled.div`
  
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #FAF9FD;
  z-index: 100; 
`

export const SelectListItem = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;   
  &:first-child {
    border-top: 0.5px solid ${Colors.borderGray};
  }
`

const renderIsOpenStateBody = (isOpen: boolean) => isOpen
    ? `
        display: flex;
    ` :
    `
        display: none;
    `
export const Wrapper = styled.div<SelectHeaderStyledProps>`
    width: 100%;
    height: 40px;
    position: relative;
    outline: none;
    user-select: none;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  
  ${SelectHeader} {
    ${({isOpen}) => renderIsOpenState(isOpen)}
  }

  ${SelectBody} {
    ${({isOpen}) => renderIsOpenStateBody(isOpen)}
  }
`
import React, {FC, useState} from 'react';
import DatePickerComponent, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import {ContainerWrapper, DatePickerHeader, Header, Label, LabelField, SelectorWrapper, Wrapper} from './style';
import {Icon} from '../Icon';
import {Input, Separator} from '../index';
import {Colors} from '../Colors';

registerLocale('ru', ru)
setDefaultLocale('ru');

export type DatePickerProps = {
  error: string;
  placeholder: string;
  label: string;
  value?: string;
  defaultValue?: string;
  onChange: (date: Date) => void;
  placement: 'left' | 'bottom' | 'top';
  dataTestId?: string;
};

const formatDate =  (v: Date) => {
  const dd = String(v.getDate()).padStart(2, '0');
  const mm = String(v.getMonth() + 1).padStart(2, '0');
  const yyyy = v.getFullYear();

  return `${dd}.${mm}.${yyyy}`;
};

const formatDotCaseDateToSnakeCase = (value: string) => {
  const [day, month, year] = value.split('.');

  return `${year}-${month}-${day}T00:00:00`;
};

const DatePicker: FC<DatePickerProps> = ({
       label ,
       placeholder,
       error,
       onChange,
       value,
       defaultValue,
    placement,
    dataTestId
}) => {
  const [startDate, setStartDate] = useState<Date>(value ? new Date(formatDotCaseDateToSnakeCase(value)) : defaultValue ? new Date(formatDotCaseDateToSnakeCase(defaultValue)) : new Date());

  const [inputValue, setInputValue] = useState<string>(value ? value : defaultValue ? defaultValue : '');
  // const [
  //   defaultDateCalendar,
  //   setDefaultDateCalendar
  // ] = useState<Date | null>(defaultValue ? new Date(defaultValue) : null);
  // const [isErrorShown, setIsErrorShown] = useState<boolean>(false);
  //
  // useEffect(() => {
  //   setIsErrorShown(true);
  // }, [error])

  const monthes = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  // useEffect(() => {
  //   error && setInnerError(error)
  // }, [error]);

  const handleSetDate = (v: Date) => {
    setStartDate(v);
    setInputValue(formatDate(v))
    onChange && onChange(v);

  }

  const CustomContainer = ({className, children}: any) => (
    <ContainerWrapper className={className} placement={placement}>
      {children}
    </ContainerWrapper>
  );

  const handleClick = (foo: any) => {
    foo();
  }

  // const handleInputChange = (v: string) => {
  //   setInputValue(v);
  //   console.log(inputEl.current);
  //   // inputEl && inputEl.current && inputEl.current.focus();
  // };

  const CustomInput = ({onClick, value}: any) => {
    console.log(value);
    return (
        <div onClick={() => handleClick(onClick)}>
          <Input
              value={inputValue ? inputValue: ""}
              error={error}
              placeholder={placeholder}
              focus={false}
              label={''}
              maxLength={10}
              disabled={true}
              dataTestId={'calendar-input'}
              // onChange={handleInputChange}
              endIcon={'calendar'}
          />
        </div>
    );
  }

  const renderCustomHeader = ({
    date,
    increaseYear,
    decreaseYear,
    decreaseMonth,
    increaseMonth,
  }: any) => (
    <DatePickerHeader onChange={(date: any) => onChange(date)}>
      <Header>
        <SelectorWrapper>
          <Icon size={'small'} icon={'arrow_left'} onClick={decreaseMonth} fill={Colors.secondaryDarkGray} dataTestId={dataTestId+ '-left-month'}/>
          <Label>{monthes[new Date(date).getMonth()]}</Label>
          <Icon size={'small'} icon={'arrow_right'} onClick={increaseMonth} fill={Colors.secondaryDarkGray} dataTestId={dataTestId+ '-right-month'}/>
        </SelectorWrapper>
        <SelectorWrapper>
          <Icon size={'small'} icon={'arrow_left'} onClick={decreaseYear} fill={Colors.secondaryDarkGray} dataTestId={dataTestId+ '-left-year'}/>
          <Label>{new Date(date).getFullYear()}</Label>
          <Icon size={'small'} icon={'arrow_right'} onClick={increaseYear} fill={Colors.secondaryDarkGray} dataTestId={dataTestId+ '-left-month'}/>
        </SelectorWrapper>
      </Header>

      <Separator />
    </DatePickerHeader>
  );
  //
  // const handleOpenCalendar = () => {
  //   if (!startDate) {
  //
  //   }
  // }
  console.log(startDate);
  return (
    <Wrapper data-test-id={dataTestId}>
      <LabelField>
        {label}
      </LabelField>
      <DatePickerComponent
        selected={startDate}
        onChange={(date: Date) => handleSetDate(date)}
        renderCustomHeader={renderCustomHeader}
        customInput={<CustomInput type='date' dataTestId={dataTestId + 'calendar-input'}/>}
        calendarContainer={CustomContainer}
        dateFormat={"dd.MM.yyyy"}
        popperPlacement="bottom"
        value={value}
        openToDate={startDate ? startDate: defaultValue ? new Date(defaultValue): new Date()}
        // disabledKeyboardNavigation
        // openToDate={value ? new Date(value) : new Date(defaultValue)}
        // onCalendarOpen={handleOpenCalendar}
      />
      {/*{isErrorShown && <Error>{error}</Error>}*/}

    </Wrapper>

  );
};

export default DatePicker;

import styled from 'styled-components';
import {Colors} from '../Colors';


export type CheckboxStyledProps = {
  isActive: boolean;
};

export const Wrapper = styled.div<{ disable?: boolean }>`
  position: relative;
  cursor: pointer;
  display: flex;
  * {
    box-sizing: border-box;
  }
  user-select: none;
  opacity: ${({ disable }) => disable ? '0.6' : '1'};
`;

export const Input = styled.input<{value?: any}>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
  height: 0;
  width: 0;
`;

export const Checkmark = styled.div<CheckboxStyledProps>`
  width: 16px;
  height: 16px;
  border-radius: 5px;
  border: 1px solid ${Colors.borderGray};
  background: ${Colors.secondaryGray};
  
  
  & > div {
    ${({isActive}) => !isActive && 'opacity: 0;'};
    width: 14px;
    height: 14px;
    background: ${Colors.green};
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    & > img {
      width: 8.75px;
    }
  }
`;

export const Label = styled.p<{isMarkOnly?: boolean}>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 12px;
  color: ${Colors.secondaryDarkGray};
  ${({isMarkOnly}) => isMarkOnly && 'margin: 0'};
`;

export const LabelWrapper = styled.div<{isMarkOnly?: boolean}>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 12px;
  color: ${Colors.secondaryDarkGray};
  ${({isMarkOnly}) => isMarkOnly && 'margin: 0'};
  p {
    margin: 0;
  }
  a {
    margin: 0;
    color: ${Colors.secondaryDarkGray};
    text-decoration: underline;
  }
  a:visited {
    color: ${Colors.secondaryDarkGray};
  }
`
